.intro-wrap {
  background: url('/static/client/assets/images/landing/Bg/bg.jpeg') center
    center/cover no-repeat;
  padding: 180px 0;
  color: #fff;
  overflow: hidden;
  position: relative;
  z-index: 99;
  .intro-eleven-Description {
    max-width: 600px;
    margin: 0 auto;
  }
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.9;
  }
  .subscription_form {
    .email_field {
      border-radius: 5px 0 0 5px !important;
      margin: 0 !important;
    }
    .btn-submit {
      border-radius: 0 5px 5px 0 !important;
    }
  }
  &:after {
    content: '';
    display: block;
    height: 100%;
    background: url('/static/client/assets/images/landing/Bg/Bg_06.png') bottom
      no-repeat;
    background-size: cover;
    position: absolute;
    bottom: -8px;
    left: 0;
    right: 0;
    z-index: -1;
  }
}

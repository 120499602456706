@import './themes/lite-purple.scss';
[tabindex='-1']:focus {
  outline: none;
}

hr {
  margin-top: 2rem;

  margin-bottom: 2rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  height: 0;
}

select,
button,
textarea,
input {
  vertical-align: baseline;
}

div {
  box-sizing: border-box;
}

html,
body {
  &[dir='rtl'],
  &[dir='ltr'] {
    unicode-bidi: embed;
  }
}

bdo[dir='rtl'] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

bdo[dir='ltr'] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

img {
  max-width: 100%;
}

a,
a:focus,
a:hover {
  text-decoration: none;
}

.left-image-wrap {
  .ngucarousel {
    position: relative;
    overflow: hidden;
    height: 100%;
    text-align: center;
    .leftRs {
      position: relative;
      left: 0%;
    }
    .rightRs {
      position: relative;
      right: -3%;
    }
  }
}

@media only screen and (max-width: 746px) {
  img {
    margin: 0px !important;
  }
}

$font-family-heading: 'Nunito', sans-serif;
$font-family-base: 'Nunito', sans-serif;
$font-size-base: 0.813rem;
// LAYOUT VARS
$topbar-height: 80px;
$topbar-height-mobile: 70px;
$sidebar-left-width: 120px;
$sidebar-left-width-mobile: 90px;
$sidebar-left-secondary-width: 220px;
$sidebar-left-secondary-width-mobile: 190px;
$side-clearance: 20px;
$body-text-color: #333;
$main-gradient: linear-gradient(
  -29deg,
  rgb(193, 15, 65) 0,
  rgb(35, 59, 136) 100%
);
$transparent-gradient: linear-gradient(
  -29deg,
  rgba(193, 15, 65, 0.8) 0,
  rgb(35, 59, 136) 100%
);
$extra-transparent-gradient: linear-gradient(
  -29deg,
  rgba(193, 15, 65, 0.8) 0,
  rgba(35, 59, 136, 0.8) 100%
);
// GLOBAL COLORS
$facebook: rgb(55, 101, 201);
$google: rgb(236, 65, 44);
$twitter: rgb(3, 159, 245);
$instagram: rgb(193, 53, 132);
$linkedin: rgb(0, 119, 181);
$dribble: #ea4c89;
$youtube: #c4302b;

.work1Wrap {
  padding: 80px 0px;
  .card {
    .card-img-top {
      max-height: 300px;
      min-height: 300px;
      // object-fit: fill;
    }
    .card-footer {
      background: transparent;
      a.text-dark {
        line-height: 10px;
      }
    }
  }
}

@media only screen and (max-width: 764px) {
  // .work1Wrap {
  //     .card {
  //         margin-bottom: 30px;
  //     }
  // }
}

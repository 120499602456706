.right-image-wrap {
  .card {
  }
  img.card {
    background: transparent;
  }
  .ngucarousel {
    position: relative;
    overflow: hidden;
    height: 100%;
    text-align: center;
    .leftRs {
      position: relative;
      left: 0%;
    }
    .rightRs {
      position: relative;
      right: -3%;
    }
  }
}

// .customizer {
//     position: fixed;
//     z-index: 9999;
//     top: 45%;
//     right: -116px;
//     transition: .3s all ease-in-out;
//     &.open {
//         right: 0;
//     }
//     .handle {
//         position: absolute;
//         display: flex;
//         top: 8px;
//         left: -24px;
//         background: $background;
//         cursor: pointer;
//         padding: 10px 2px;
//         border-top-left-radius: 4px;
//         border-bottom-left-radius: 4px;
//         box-shadow: -3px 0px 4px rgba(0, 0, 0, 0.06);
//         color: $primary;
//         font-size: 20px;
//         transition: .3s all ease-in-out;
//         &:hover {
//             background: $primary;
//             color: $white;
//         }
//     }
//     .customizer-body {
//         padding: 1rem;
//         border-top-left-radius: 4px;
//         border-bottom-left-radius: 4px;
//         background: $background;
//         box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.08);
//         .colors {
//             .color {
//                 width: 24px;
//                 height: 24px;
//                 display: inline-block;
//                 border-radius: 50%;
//                 margin: 8px;
//                 text-indent: -10000px;
//                 &.purple {
//                     background: #663399;
//                 }
//                 &.blue {
//                     background: #2f47c2;
//                 }
//             }
//         }
//     }
// }

// [dir="rtl"] {
//     .customizer {
//         position: fixed;
//         z-index: 9999;
//         top: 45%;
//         left: -116px;
//         right: unset;
//         transition: .3s all ease-in-out;
//         &.open {
//             right: unset;
//             left: 0;
//         }
//         .handle {
//             position: absolute;
//             display: flex;
//             top: 8px;
//             right: -24px;
//             left: unset;
//             background: $background;
//             cursor: pointer;
//             padding: 10px 2px;
//             border-top-left-radius: 0px;
//             border-top-right-radius: 4px;
//             border-bottom-left-radius: 0px;
//             border-bottom-right-radius: 4px;
//             box-shadow: 3px 0px 4px rgba(0, 0, 0, 0.06);
//             color: $primary;
//             font-size: 20px;
//             transition: .3s all ease-in-out;
//             &:hover {
//                 background: $primary;
//                 color: $white;
//             }
//         }
//         .customizer-body {
//             padding: 1rem;
//             border-top-left-radius: 4px;
//             border-bottom-left-radius: 4px;
//             background: $background;
//             box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.08);
//             .colors {
//                 .color {
//                     width: 24px;
//                     height: 24px;
//                     display: inline-block;
//                     border-radius: 50%;
//                     margin: 8px;
//                     text-indent: 10000px;
//                     &.purple {
//                         background: #663399;
//                     }
//                     &.blue {
//                         background: #2f47c2;
//                     }
//                 }
//             }
//         }
//     }
// }

// .customizer {
//   position: fixed;
//   z-index: 99;
//   top: 100px;
//   right: -380px;
//   transition: 0.3s all ease-in-out;
//   width: 380px;
//   height: 100vh;
//   &.open {
//     right: 0;
//   }
//   .handle {
//     position: absolute;
//     display: flex;
//     top: 8px;
//     left: -36px;
//     background: $primary;
//     cursor: pointer;
//     padding: 10px 8px;
//     border-top-left-radius: 4px;
//     border-bottom-left-radius: 4px;
//     box-shadow: -3px 0px 4px rgba(0, 0, 0, 0.06);
//     color: $white;
//     font-size: 20px;
//     transition: 0.3s all ease-in-out;
//     &:hover {
//       background: $primary;
//       color: $white;
//     }
//   }
//   .customizer-body {
//     // padding: 1rem;
//     border-top-left-radius: 10px;
//     border-bottom-left-radius: 10px;
//     background: $background;
//     box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06),
//       0 1px 4px rgba(0, 0, 0, 0.08);
//     max-height: calc(100vh - 140px);
//     overflow-y: scroll;
//     overflow-x: visible;
//     .layouts {
//       display: flex;
//       flex-wrap: wrap;
//       margin: 0 -8px;
//       .layout-box {
//         position: relative;
//         margin: 0 8px;
//         box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06),
//           0 1px 4px rgba(0, 0, 0, 0.03);
//         border-radius: 8px;
//         overflow: hidden;
//         cursor: pointer;
//         width: calc(50% - 16px);
//         border: 1px solid rgba(0, 0, 0, 0.08);
//         img {
//           width: 180px;
//         }
//         i {
//           display: none;
//           position: absolute;
//           top: 0;
//           text-align: center;
//           right: 0;
//           height: 24px;
//           font-size: 18px;
//           line-height: 24px;
//           width: 32px;
//           color: #ffffff;
//           background: #663399;
//           border-radius: 0 0 0 6px;
//         }
//         &.active {
//           border: 1px solid $primary;
//           i {
//             display: inline-block;
//           }
//         }
//       }
//     }
//     .colors {
//       display: flex;
//       flex-wrap: wrap;
//       .color {
//         width: 36px;
//         height: 36px;
//         display: inline-block;
//         border-radius: 50%;
//         margin: 8px;
//         text-align: center;
//         box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06),
//           0 1px 4px rgba(0, 0, 0, 0.03);
//         cursor: pointer;
//         &.purple {
//           background: #663399;
//         }
//         &.blue {
//           background: #2f47c2;
//         }
//         i {
//           display: none;
//           color: #ffffff;
//           font-size: 18px;
//           line-height: 36px;
//         }
//         &.active {
//           i {
//             display: unset;
//           }
//         }
//       }
//     }
//   }
// }
.customizer {
  max-width: 350px;
  height: calc(100vh - 250px);
  position: fixed;
  top: 200px;
  background: #fff;
  z-index: 99;
  border-radius: 10px;
  left: -350px;
  transition: all 0.5s ease-in-out;
  .colors_wrap {
    height: calc(100vh - 300px);
    position: relative;
  }
  .btn-circle {
    // height: 50px;
    // width: 50px;
    border-radius: 50%;
    padding: 22px;
    border: 0px;
  }
  .handle {
    height: 40px;
    width: 40px;
    position: absolute;
    right: -15px;
    background: #fff;
    font-size: 20px;
    line-height: 46px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    z-index: 99;
  }
  .setting_wrap {
    width: 40px;
    float: right;
    height: 40px;
    background: #fff;
    margin-right: -28px;
    line-height: 48px;
    text-align: center;
    font-size: 22px;
    border-radius: 5px;
    cursor: pointer;
    padding-left: 5px;
  }
  &.show {
    left: 0px;
  }
}

@media (max-width: 767px) {
  .customizer {
    display: none;
    &.show {
      height: 500px;
      overflow-y: scroll;
      top: 120px;
      .setting_wrap {
        width: 40px;
        float: left;
        height: 40px;
        background: #fff;
        margin-right: 0px;
        line-height: 48px;
        text-align: center;
        font-size: 22px;
        border-radius: 5px;
        cursor: pointer;
        padding-left: 5px;
      }
    }
  }
}

// [dir="rtl"] {
//   .customizer {
//     right: auto;
//     left: -380px;
//     &.open {
//       right: auto;
//       left: 0;
//     }
//     .handle {
//       top: 8px;
//       left: auto;
//       right: -36px;
//       border-top-left-radius: 0;
//       border-top-right-radius: 4px;
//       border-bottom-left-radius: 0;
//       border-bottom-right-radius: 4px;
//       box-shadow: -3px 0px 4px rgba(0, 0, 0, 0.06);
//     }
//   }
// }

.form-group {
  position: relative;
  margin-bottom: 10px;
  label {
    font-size: 12px;
    color: $gray-600;
    margin-bottom: 4px;
  }
}

.form-control {
  border: initial;
  outline: initial !important;
  background: $gray-100;
  // border: 1px solid $gray-400;
  color: $foreground;
  &::placeholder {
    color: $gray-500;
  }
  &.rounded,
  &.form-control-rounded {
    border-radius: 20px;
  }
}

select.form-control {
  -webkit-appearance: none;
}

.input-group {
  [type='text'].form-control {
    height: 34px;
  }
}

.input-group-append {
  .btn {
    height: 34px;
    border: 0;
  }
}

[ngbdatepicker] {
  height: 34px;
}

/* checkbox-custom */

.checkbox {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;
  &:hover input ~ .checkmark {
    background-color: $gray-500;
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ .checkmark {
      background-color: $primary;
    }
    &:checked ~ .checkmark:after {
      display: block;
    }
    &[disabled] ~ * {
      color: $gray-300;
    }
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 4px;
    background-color: $gray-300;
    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 0;
      top: 0;
      right: 0;
      width: 4px;
      bottom: 0;
      margin: auto;
      height: 8px;
      border: solid #{$background};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
}

@each $name, $value in $theme-colors {
  .checkbox-#{$name} {
    input {
      &:checked ~ .checkmark {
        background-color: $value !important;
      }
    }
  }
}

@each $name, $value in $theme-colors {
  .checkbox-outline-#{$name} {
    &:hover input ~ .checkmark {
      background-color: $gray-200;
    }
    input {
      &:checked ~ .checkmark {
        background-color: $background !important;
      }
    }
    .checkmark {
      background: $background;
      border: 1px solid #{$value};
      &:after {
        border-color: $value;
      }
    }
  }
}

// RADIO BUTTON
.radio {
  @extend .checkbox;
  .checkmark {
    border-radius: 50%;
    &:after {
      height: 6px;
      width: 6px;
      border-radius: 50%;
      background: white;
    }
  }
}

@each $name, $value in $theme-colors {
  .radio-#{$name} {
    input {
      &:checked ~ .checkmark {
        background-color: $value;
      }
    }
  }
}

@each $name, $value in $theme-colors {
  .radio-outline-#{$name} {
    &:hover input ~ .checkmark {
      background-color: $gray-200;
    }
    input {
      &:checked ~ .checkmark {
        background-color: $background !important;
      }
    }
    .checkmark {
      background: $background;
      border: 1px solid #{$value};
      &:after {
        border: 0;
        background: $value;
      }
    }
  }
}

// SWITCH
.switch {
  position: relative;
  display: inline-block;
  padding-left: 50px;
  height: 16px;
  span:not(.slider) {
    position: relative;
    top: -2px;
    cursor: pointer;
  }
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    width: 42px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 34px;
    background-color: $gray-300;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .slider:before {
    position: absolute;
    content: '';
    height: 24px;
    width: 24px;
    left: -1px;
    bottom: -4px;
    background-color: $background;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }
  input:checked + .slider {
    background-color: $primary;
  }
  input:focus + .slider {
    box-shadow: 0 0 1px $primary;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
}

@each $name, $value in $theme-colors {
  .switch-#{$name} {
    input:checked + .slider {
      background-color: $value;
    }
    input:focus + .slider {
      box-shadow: 0 0 1px $value;
    }
  }
}

[dir='rtl'] {
  .checkbox,
  .radio {
    display: block;
    position: relative;
    padding-left: 28px;
    margin-bottom: 12px;
    cursor: pointer;
    user-select: none;
    width: 101%;
  }
  .form-check-label {
    margin-right: 1rem !important;
  }
}

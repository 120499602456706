@mixin directional-gradient($dir, $from, $to, $stop: 100%) {
  /* fallback/image non-cover color */
  background-color: $from;
  /* Firefox 3.6+ */
  background-image: linear-gradient($dir, $from 0%, $to $stop) !important;
  background-image: -moz-linear-gradient($dir, $from 0%, $to $stop) !important;
  /* Safari 4+, Chrome 1+ */
  background-image: -webkit-gradient(
    linear,
    $dir,
    right top,
    color-stop(0%, $from),
    color-stop($stop, $to)
  ) !important;
  /* Safari 5.1+, Chrome 10+ */
  background-image: -webkit-linear-gradient(
    $dir,
    $from 0%,
    $to $stop
  ) !important;
  /* Opera 11.10+ */
  background-image: -o-linear-gradient($dir, $from 0%, $to $stop) !important;
  /* IE10+ */
  background: -ms-linear-gradient($dir, $from 0%, $to $stop) !important;
  /* Standard */
  background: linear-gradient($dir, $from 0%, $to $stop) !important;
  /* IE6-9 */
  //  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='$from', endColorstr='$to', GradientType=1);
}

@mixin directional-gradient-eva($dir, $from, $to, $stop: 100%) {
  /* fallback/image non-cover color */
  // bg image
  /* Firefox 3.6+ */
  background-image: linear-gradient($dir, $from 0%, $to $stop) !important;
  background-image: -moz-linear-gradient($dir, $from 0%, $to $stop) !important;
  /* Safari 4+, Chrome 1+ */
  background-image: -webkit-gradient(
    linear,
    $dir,
    right top,
    color-stop(0%, $from),
    color-stop($stop, $to)
  ) !important;
  /* Safari 5.1+, Chrome 10+ */
  background-image: -webkit-linear-gradient(
    $dir,
    $from 0%,
    $to $stop
  ) !important;
  /* Opera 11.10+ */
  background-image: -o-linear-gradient($dir, $from 0%, $to $stop) !important;
  /* IE6-9 */
  // filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='$from', endColorstr='$to', GradientType=1);
}

.solution-container:hover {
  background: linear-gradient(-29deg, #c10f41, #233b88) !important;
}
.solution-container i {
  -webkit-text-fill-color: #2c2b2b;
}
.solution-container:hover i {
  -webkit-text-fill-color: #fff;
}
.solution-container:hover h2 {
  color: #fff !important;
}
.solution-container:hover p {
  color: #fff !important;
}

.pricing-four__titles:before {
  content: '';
  position: absolute;
  width: 50px;
  height: 1px;
  background: black;
  top: 85px;
  margin: 0 auto;
  /* bottom: 0; */
  left: 0;
  right: 0;
  transition: all 0.5 ease-in;
  transition: 0.5s ease-out;
}
.pricing-four__contents:hover .pricing-four__titles:before {
  width: 300px;
}
.pricing-four__titles--white:before {
  content: '';
  position: absolute;
  width: 50px;
  height: 1px;
  background: #fff;
  top: 85px;
  margin: 0 auto;
  /* bottom: 0; */
  left: 0;
  right: 0;
  transition: all 0.5 ease-in;
  transition: 0.5s ease-out;
}
.pricing-four__contents:hover .pricing-four__titles--white:before {
  width: 300px;
}
.eva--white {
  -webkit-text-fill-color: #fff !important;
}
